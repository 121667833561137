<template>
  <div class="container">
    <transition>
      <div>
        <BaseHeader title="Dashboard" class="header mb-5" data-anima="top">
          <button v-if="ambassadorEnabled && ambassadorAcceptedTerms" v-b-modal.links-new @click="openComissions"
            class="btn p-2" style="background-color: #eee">
            <b-row class="align-items-center" style="color: #32455f; font-weight: 500">
              <img src="@/assets/img/icons/my-comission-blue.svg" class="pr-2" />
              {{ $t("views.seller.links.text_1141") }}
            </b-row>
          </button>
        </BaseHeader>
        <div class="conteudo">
          <div class="center">
            <div class="card-validation" v-if="
              isMobile &&
              (!status_validation.status ||
                status_validation.status == 'INITIATED' ||
                status_validation.status == 'DISAPPROVED')
            ">
              <p>
                {{ $t("views.dashboard.text_986") }}
              </p>
              <BaseButton variant="info2" @click="openVerification">{{
                $t("views.dashboard.text_987")
                }}</BaseButton>
            </div>
            <a v-if="!openBannerInModal" :href="updatedUrlRedirect" target="_blank" class="banner" data-anima="bottom">
              <img v-if="banner_url !== ''" :src="banner_url" alt="banner" />
              <div v-else class="banner-default-loading banner" alt="banner">
                <b-spinner label="Loading..."></b-spinner>
              </div>
            </a>
            <template v-else>
              <div class="banner" data-anima="bottom" @click="openNpsForm" style="cursor:pointer">
                <img v-if="banner_url !== ''" :src="banner_url" alt="banner" />
                <div v-else class="banner-default-loading banner" alt="banner">
                  <b-spinner label="Loading..."></b-spinner>
                </div>
              </div>
              <modal-banner :url="updatedUrlRedirect" :is-mobile="isMobile" />
            </template>
            <!-- Today Sales -->
            <div class="indicadores">
              <div class="item">
                <img src="@/assets/img/icons/bag-sale.svg" />
                <p>{{ $t("views.dashboard.text_988") }}</p>
                <div v-if="statementsToday">
                  <h4 v-if="saldoOk">
                    {{ currency.currency_symbol }}
                    {{ formatMoney(statementsToday) }}
                  </h4>
                  <h4 v-else id="no-saldo">-</h4>
                </div>
                <div v-else>
                  <h4 v-if="saldoOk">{{ currency.currency_symbol }} 0,00</h4>
                  <h4 v-else id="no-saldo">-</h4>
                </div>
              </div>

              <div class="item">
                <img src="@/assets/img/icons/wallet.svg" />
                <p>{{ $t("views.dashboard.text_989") }}</p>
                <div v-if="statementsLastMonth">
                  <h4 v-if="saldoOk">
                    {{ currency.currency_symbol }}
                    {{ formatMoney(statementsLastMonth) }}
                  </h4>
                  <h4 v-else id="no-saldo">-</h4>
                </div>
                <div v-else>
                  <h4 v-if="saldoOk">{{ currency.currency_symbol }} 0,00</h4>
                  <h4 v-else id="no-saldo">-</h4>
                </div>
              </div>
            </div>

            <div class="card-transacoes mt-4 mb-4" v-if="isMobile">
              <div class="saldos">
                <!-- My Balance -->
                <div class="mb-3 d-flex justify-content-between align-items-center">
                  <span>{{ $t("views.dashboard.text_990") }}</span>
                  <SelectCurrency v-show="interAccess" :currency="currency" @update-coin="updateCoin" />
                </div>
                <div class="saldo-livre">
                  <div v-if="statementsAvailableCount">
                    <h5 v-if="saldoOk">
                      {{ currency.currency_symbol }}
                      {{ formatMoney(statementsAvailableCount) }}
                    </h5>
                    <h5 v-else id="no-saldo">-</h5>
                  </div>
                  <div v-else>
                    <h5 v-if="saldoOk">{{ currency.currency_symbol }} 0,00</h5>
                    <h5 v-else id="no-saldo">-</h5>
                  </div>

                  <div @click="saldoOk = !saldoOk" class="btn-esconde" :class="{ ativo: saldoOk }"></div>
                </div>
                <!-- Blocked Balance -->
                <div class="saldo-block">
                  <span>{{ $t("views.dashboard.text_991") }}</span>
                  <div v-if="statementsBlockCount">
                    <h5 v-if="saldoOk">
                      {{ currency.currency_symbol }}
                      {{ formatMoney(statementsBlockCount) }}
                    </h5>
                    <h5 v-else id="no-saldo" class="block-prelod">-</h5>
                  </div>
                  <div v-else>
                    <h5 v-if="saldoOk">{{ currency.currency_symbol }} 0,00</h5>
                    <h5 v-else id="no-saldo" class="block-prelod">-</h5>
                  </div>
                </div>
              </div>
              <!-- Bar/Circle Chart Title -->
              <div class="titulo-grafico">
                <span>{{ $t("views.dashboard.text_992") }}</span>
                <span class="periodo-grafico">{{ $t("views.dashboard.text_993") }}
                </span>
              </div>
              <!-- Bar/Circle Chart -->
              <div class="grafico-bar">
                <BarChart :vendas_device="vendas_device" v-show="!loading_statements" />
                <!-- Loading -->
                <div class="container-loading" v-if="loading_statements">
                  <b-spinner label="Loading..."></b-spinner>
                </div>
              </div>
              <!-- Recent Sales -->
              <div class="transacoes-recentes">
                <div class="titulo">
                  <span>{{ $t("views.dashboard.text_994") }}</span>
                  <img src="@/assets/img/icons/transacoes.svg" />
                </div>
                <div class="transacao">
                  <template v-if="vendas_recentes.length">
                    <div v-for="venda in vendas_recentes" :key="venda.id" class="item" @click="openSale(venda.id)">
                      <div class="dados-transacao">
                        <img v-if="venda.product && venda.product.images.length" :src="venda.product.images[0].path"
                          class="icone" />
                        <div v-else class="icone">
                          <img src="@/assets/img/icons/imagem-modal.svg" class="erro-img" />
                        </div>

                        <div class="dado">
                          <div class="up">
                            <h5>
                              {{
                                venda.product.name.length > 22
                                  ? venda.product.name.substr(0, 22) + "..."
                                  : venda.product.name
                              }}
                            </h5>
                            <span
                              >{{ currency.currency_symbol }}
                              {{ formatMoney(venda.seller_balance) }}
                            </span>
                          </div>
                          <div class="down">
                            <span>{{ nameClient(venda.client.name) }}</span>
                            <span>{{ venda.created_at | moment }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <router-link to="/vendas" class="button ver-vendas">
                    {{ $t("views.dashboard.text_995") }}
                  </router-link>
                </div>
                <div class="transacao" v-if="vendas_recentes_load">
                  <div class="item load">
                    <div class="dados-transacao">
                      <div class="icone"></div>
                      <div class="dado">
                        <div class="up">
                          <h5>-</h5>
                          <span>-</span>
                        </div>
                        <div class="down">
                          <span>-</span>
                          <span>-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item load">
                    <div class="dados-transacao">
                      <div class="icone"></div>
                      <div class="dado">
                        <div class="up">
                          <h5>-</h5>
                          <span>-</span>
                        </div>
                        <div class="down">
                          <span>-</span>
                          <span>-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item load">
                    <div class="dados-transacao">
                      <div class="icone"></div>
                      <div class="dado">
                        <div class="up">
                          <h5>-</h5>
                          <span>-</span>
                        </div>
                        <div class="down">
                          <span>-</span>
                          <span>-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <span class="info-week">{{ $t("views.dashboard.text_993") }}</span>

            <div class="grafico-area">
              <AreaChart ref="areaChart" v-if="!loading_statements" :currency="currency.currency_symbol" />
              <!-- Loading -->
              <div class="container-loading" v-if="loading_statements">
                <b-spinner label="Loading..."></b-spinner>
              </div>
            </div>
            <!-- Top 5 Products -->
            <div class="top" v-if="top_products.length">
              <h4>{{ $t("views.dashboard.text_996") }}</h4>
              <div v-for="(produto, index) in top_products" :key="index" class="top-lista">
                <div class="dados-produto">
                  <img v-if="
                    produto.product &&
                    produto.product.images &&
                    produto.product.images.length
                  " :src="produto.product.images[0].path" class="icone-imagem" />
                  <div v-else class="icone-imagem">
                    <img src="@/assets/img/icons/imagem-modal.svg" class="erro-img" />
                  </div>
                  <div class="dado" v-if="produto.product">
                    <h5>{{ produto.product.name }}</h5>
                    <span>{{ produto.product.category.name }}</span>
                  </div>
                  <div v-else>
                    <h5>{{ $t("views.dashboard.text_997") }}</h5>
                    <span>-</span>
                  </div>
                </div>

                <!-- <div class="valores" v-if="produto.product"> -->
                <div class="valores">
                  <h5 v-if="produto.total">
                    {{ currency.currency_symbol }}
                    {{ formatMoney(produto.total) }}
                  </h5>
                  <h5 v-if="!produto.total">
                    {{ currency.currency_symbol }} 0,00
                  </h5>

                  <span>
                    {{ produto.count }}
                    {{ $t("views.dashboard.text_2377") }}</span>
                </div>
              </div>
            </div>
            <div v-if="top_products_load" class="top">
              <div class="top-lista load">
                <div class="dados-produto">
                  <img src="" class="icone" />
                  <div class="dado">
                    <h5>-</h5>
                    <span>-</span>
                  </div>
                </div>

                <!-- <div class="valores" v-if="produto.product"> -->
                <div class="valores">
                  <h5>-</h5>
                  <span>-</span>
                </div>
              </div>
              <div class="top-lista load">
                <div class="dados-produto">
                  <img src="" class="icone" />
                  <div class="dado">
                    <h5>-</h5>
                    <span>-</span>
                  </div>
                </div>

                <!-- <div class="valores" v-if="produto.product"> -->
                <div class="valores">
                  <h5>-</h5>
                  <span>-</span>
                </div>
              </div>
              <div class="top-lista load">
                <div class="dados-produto">
                  <img src="" class="icone" />
                  <div class="dado">
                    <h5>-</h5>
                    <span>-</span>
                  </div>
                </div>

                <!-- <div class="valores" v-if="produto.product"> -->
                <div class="valores">
                  <h5>-</h5>
                  <span>-</span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="card-validation" data-anima="top" v-if="
              !status_validation.status ||
              status_validation.status == 'INITIATED' ||
                status_validation.status == 'DISAPPROVED'
            ">
              <p>
                {{ $t("views.dashboard.text_986") }}
              </p>
              <BaseButton variant="info2" @click="openVerification">
                {{ $t("views.dashboard.text_987") }}
              </BaseButton>
            </div>
            <div class="card-transacoes" v-if="!isMobile">
              <div class="saldos">
                <!-- My Balance -->
                <div class="d-flex justify-content-between align-items-center">
                  <span>{{ $t("views.dashboard.text_990") }}</span>
                  <SelectCurrency v-show="interAccess" :currency="currency" @update-coin="updateCoin" />
                </div>
                <!-- Free Balance -->
                <div class="saldo-livre">
                  <div v-if="statementsAvailableCount">
                    <h5 v-if="saldoOk">
                      {{ currency.currency_symbol }}
                      {{ formatMoney(statementsAvailableCount) }}
                    </h5>
                    <h5 v-else id="no-saldo">-</h5>
                  </div>
                  <div v-else>
                    <h5 v-if="saldoOk">{{ currency.currency_symbol }} 0,00</h5>
                    <h5 v-else id="no-saldo">-</h5>
                  </div>

                  <div @click="saldoOk = !saldoOk" class="btn-esconde" :class="{ ativo: saldoOk }"></div>
                </div>
                <!-- Blocked Balance -->
                <div class="saldo-block">
                  <span>{{ $t("views.dashboard.text_991") }}</span>
                  <div v-if="statementsBlockCount">
                    <h5 v-if="saldoOk">
                      {{ currency.currency_symbol }}
                      {{ formatMoney(statementsBlockCount) }}
                    </h5>
                    <h5 v-else id="no-saldo" class="block-prelod">-</h5>
                  </div>
                  <div v-else>
                    <h5 v-if="saldoOk">{{ currency.currency_symbol }} 0,00</h5>
                    <h5 v-else id="no-saldo" class="block-prelod">-</h5>
                  </div>
                </div>
              </div>
              <!-- Bar/Circle Chart Title -->
              <div class="titulo-grafico">
                <span>{{ $t("views.dashboard.text_992") }}</span>
                <span class="periodo-grafico">{{ $t("views.dashboard.text_993") }}
                </span>
              </div>
              <!-- Bar/Circle Chart -->
              <div class="grafico-bar">
                <BarChart :vendas_device="vendas_device" v-show="!loading_statements" />
                <!-- Loading -->
                <div class="container-loading" v-if="loading_statements">
                  <b-spinner label="Loading..."></b-spinner>
                </div>
              </div>

              <div class="transacoes-recentes">
                <!-- Recent Sales Title-->
                <div class="titulo">
                  <span>{{ $t("views.dashboard.text_2378") }}</span>
                </div>
                <div class="transacao">
                  <!-- Recent Sales List -->
                  <template v-if="vendas_recentes.length">
                    <div v-for="venda in vendas_recentes" :key="venda.id" class="item" @click="openSale(venda.id)">
                      <div class="dados-transacao">
                        <img v-if="venda.product.images.length" :src="venda.product.images[0].path" class="icone" />
                        <div v-else class="icone">
                          <img src="@/assets/img/icons/imagem-modal.svg" class="erro-img" />
                        </div>
                        <div class="dado">
                          <div class="up">
                            <h5>
                              {{
                                venda.product.name.length > 22
                                  ? venda.product.name.substr(0, 22) + "..."
                                  : venda.product.name
                              }}
                            </h5>
                            <span
                              >{{ currency.currency_symbol }}
                              {{ formatMoney(venda.seller_balance) }}
                            </span>
                          </div>
                          <div class="down">
                            <span>{{ nameClient(venda.client.name) }}</span>
                            <span>{{ venda.created_at | moment }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <!-- All Sales -->
                  <router-link to="/vendas" class="button ver-vendas">
                    {{ $t("views.dashboard.text_2379") }}
                  </router-link>
                </div>
                <!-- Loading -->
                <div class="transacao" v-if="vendas_recentes_load">
                  <div class="item load">
                    <div class="dados-transacao">
                      <div class="icone"></div>
                      <div class="dado">
                        <div class="up">
                          <h5>-</h5>
                          <span>-</span>
                        </div>
                        <div class="down">
                          <span>-</span>
                          <span>-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item load">
                    <div class="dados-transacao">
                      <div class="icone"></div>
                      <div class="dado">
                        <div class="up">
                          <h5>-</h5>
                          <span>-</span>
                        </div>
                        <div class="down">
                          <span>-</span>
                          <span>-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item load">
                    <div class="dados-transacao">
                      <div class="icone"></div>
                      <div class="dado">
                        <div class="up">
                          <h5>-</h5>
                          <span>-</span>
                        </div>
                        <div class="down">
                          <span>-</span>
                          <span>-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <Informative />
    <ModalValidation @close="getStatusValidation" />
    <!--<CallEvaluation /> -->
  </div>
</template>

<script>

import AreaChart from "@/components/chart/AreaChart.vue";
import BarChart from "@/components/chart/BarChart.vue";
import Informative from "@/components/informative";
import SelectCurrency from "@/components/SelectCurrency.vue";
import ModalBanner from '@/components/shared/ModalBanner.vue';
import Money from "@/mixins/money";
import Utils from "@/mixins/utils";
import _ from "lodash";
import moment from "moment-timezone";

import AuthServiceClub from "@/services/resources/AuthServiceClub";
import AuthServiceEnvios from "@/services/resources/AuthServiceEnvios";
import DashboardService from "@/services/resources/DashboardService";
import MetaService from "@/services/resources/MetaService";
import SubUserService from "@/services/resources/SubUsersService";
import UserService from "@/services/resources/UserService";
import ValidationService from "@/services/resources/ValidationService";

import Raphael from "raphael/raphael";
global.Raphael = Raphael;

const serviceUser = UserService.build();
const serviceMeta = MetaService.build();
const serviceSubUser = SubUserService.build();
const serviceValidation = ValidationService.build();
const serviceClub = AuthServiceClub.build();
const serviceEnvios = AuthServiceEnvios.build();
const serviceDashboard = DashboardService.build();

import Cookies from "js-cookie";

export default {
  name: "Dashboard",
  mixins: [Utils,Money],
  components: {
    AreaChart,
    BarChart,
    Informative,
    SelectCurrency,
    ModalBanner,
  },
  data() {
    return {
      banner_url: "",
      openBannerInModal: false,
      url_redirect: "",
      loading: true,
      loading_statements: false,
      status_validation: {
        status: "status",
        observation: "",
      },
      floatApp: true,
      vendas_semana: [],
      vendas_device: [],
      top_products: [],
      top_products_load: false,
      statementsBlockCount: 0,
      statementsAvailableCount: 0,
      statementsToday: 0,
      statementsLastMonth: 0,
      vendas_recentes: [],
      vendas_recentes_load: false,
      saldoOk: true,
      client: {
        width: 0,
      },
      currency: {
        id: 1,
        name: "Real Brasileiro",
        currency: "BRL",
        currency_symbol: "R$",
      },
      interAccess: false,
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YY HH:mm");
    },
  },
  computed: {
    updatedUrlRedirect() {
      return this.replaceDynamicParams(this.url_redirect);
    },
    isMobile() {
      return this.client.width <= 768;
    },
    getPermissionsCommissions() {
      return this.$store.getters.getAllPermissionsNames && this.$store.getters.getAllPermissionsNames.length > 0 ? this.$store.getters.getAllPermissionsNames.includes('Commissions') : false;
    },
    isLoadingStatements() {
      if(this.loading_statements) this.statementsToday = 0;
    },
  },

  methods: {
    openNpsForm() {
      this.showModalBanner = !this.showModalBanner;
        this.$children.forEach(function(child) {
          if (typeof child.changeModalBanner === 'function') {
            child.changeModalBanner();
          }
        });
    },
    replaceDynamicParams(url) {
      // Substitui todas as variáveis dinâmicas na URL
      const params = {
        userID: this.userID,
        location: this.$route.name,
        // Adicione outros parâmetros dinâmicos aqui, se necessário
      };

      // Substitui cada variável na URL
      return url.replace(/{{(\w+)}}/g, (match, key) => {
        return params[key] !== undefined ? params[key] : 0; // Substitui por 0 se não existir
      });
    },
    checkInvite() {

      const code = Cookies.get('child_code');
      const parent_id = Cookies.get('parent_user_id');

      if (code && parent_id) {
        let dataInvite = {
          id: "invite/accept",
          code: code,
          parent_id: parent_id,
        };

        if (dataInvite.code && dataInvite.parent_id) {
          serviceSubUser
            .createId(dataInvite)
            .then((response) => {
              this.$emit("close");
              this.$root.$emit("updateListUser");
            })
            .catch((err) => {                  
              if (err.response.data && err.response.data.message) {
                this.$bvToast.toast(err.response.data.message, {
                  title: "Grupo de Permissões",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                this.$emit("close");
                this.$root.$emit("updateListUser");
              }
            });
        }
      }
    },
    existExternalParams() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const type = urlParams.get("type");
      const code = urlParams.get("code");
      const parent_id = urlParams.get("parent_id");
      if (type === "club" || type === "envios") {
        this.externalSubmit(type);
      } else if (parent_id && code) {
        this.modalSubUser();
      }
    },
    modalSubUser() {
      if (document.getElementById("modal-group-accept") === null) {
        this.$root.$emit("bv::show::modal", "modal-group-accept", "#btnShow");
      }
    },
    externalSubmit(type) {
      var service = "";
      if (type === "club") {
        service = serviceClub;
      } else {
        service = serviceEnvios;
      }

      service
        .toClub()
        .then((resp) => {
          if (resp.string) {
            if (type === "club") {
              window.location.href =
                process.env.VUE_CLUB_API_HOST +
                "?rdr=" +
                resp.string +
                "&type=greenn";
            } else {
              window.location.href =
                process.env.VUE_ENVIOS_API_HOST +
                "/login?rdr=" +
                resp.string +
                "&type=greenn";
            }
          }
        })
        .catch(() => { });
    },
    openVerification() {
      this.$bvModal.show("identity-validation");
    },
    getStatusValidation() {
      if (typeof localStorage.user === 'undefined') {
        return;
      }
      const user = JSON.parse(localStorage.user);
      
      let data = {
        id: this.userID,
      };
      serviceValidation
        .read(data)
        .then((response) => {
          this.status_validation = response;
          if (
            !this.status_validation.status ||
            this.status_validation.status == "status"
          ) {
            this.status_validation = {
              status: "INITIATED",
              observation: "",
            };
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchMetas() {
      let data = {
        id: "onboarding",
      };

      serviceMeta.read(data).then((response) => {
        if (response.meta_value == "0") {
          this.$bvModal.show("informative");
        }
      });
    },
    detectOs() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // if (/windows phone/i.test(userAgent)) {
      //     return "Windows Phone";
      // }
      if (/android/i.test(userAgent)) {
        return "Android";
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "Desktop";
    },
    openHelp() {
      window.open("https://greenn.crisp.help/pt-br/");
    },
    openApp(type) {
      if (type == "apple") {
        window.open("https://apps.apple.com/us/app/greenn/id1567979861");
      }
      if (type == "play-store") {
        window.open(
          "https://play.google.com/store/apps/details?id=br.com.greenn"
        );
      }
    },
    nameClient(name_client) {
      return name_client.toString().length > 20
        ? name_client.toString().substr(0, 20) + "..."
        : name_client;
    },
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },
    date(date) {
      return moment(date).format("DD/MM/YY");
    },
    openSale(id_venda) {
      this.$router.push({
        path: "/vendas",
        query: {
          s_id: id_venda,
        },
      });
    },
    fetchVendasRecentes() {
      if(!this.$store.getters.getVerifyPermissions.Dashboard) return false;
      let array = [];
      this.vendas_recentes_load = true;

      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      let lastMonth = new Date();
      lastMonth.setDate(today.getDate() - 30);
      lastMonth.setHours(0, 0, 0, 0);

      let data = {
        types: ["sales"],
        today: moment
          .tz(today, "America/Sao_Paulo")
          .utc()
          .format("YYYY-MM-DDT00:00:00-03:00"),
        last_week: moment
          .tz(lastWeek, "America/Sao_Paulo")
          .utc()
          .format("YYYY-MM-DDT00:00:00-03:00"),
        last_month: moment
          .tz(lastMonth, "America/Sao_Paulo")
          .utc()
          .format("YYYY-MM-DDT00:00:00-03:00"),
        currency_id: this.interAccess ? this.currency.id : 1,
      };

      serviceDashboard
        .search(data)
        .then((response) => {
          response.salesLastWeek.forEach((item, index) => {
            if (index < 5) array.push(item);
          });
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.vendas_recentes = array;
          this.vendas_recentes_load = false;
          this.loading = false;
        });
    },
    fetchHeader() {
      if(!this.$store.getters.getVerifyPermissions.Dashboard) return false;
      this.loading_statements = true;
      this.saldoOk = false;

      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      let lastMonth = new Date();
      lastMonth.setDate(today.getDate() - 30);
      lastMonth.setHours(0, 0, 0, 0);

      let data = {
        types: ["statements"],
        today: moment
          .tz(today, "America/Sao_Paulo")
          .utc()
          .format("YYYY-MM-DDT00:00:00-03:00"),
        last_week: moment
          .tz(lastWeek, "America/Sao_Paulo")
          .utc()
          .format("YYYY-MM-DDT00:00:00-03:00"),
        last_month: moment
          .tz(lastMonth, "America/Sao_Paulo")
          .utc()
          .format("YYYY-MM-DDT00:00:00-03:00"),
        currency_id: this.interAccess ? this.currency.id : 1,
      };

      serviceDashboard
        .search(data)
        .then((response) => {
          this.statementsAvailableCount = response.statementsAvailable;
          this.statementsBlockCount = response.statementsBlock;
          this.statementsToday = response.statementsToday;
          this.statementsLastMonth = response.statementsLastMonth || 0;

          this.vendas_semana = response.statementsLastWeekDetail;
          this.vendas_device = [
            {
              device: "Desktop",
              count: response.statementsDesktopLastWeek,
            },
            {
              device: "Mobile",
              count: response.statementsMobileLastWeek,
            },
          ];
          this.loading_statements = false;
          this.saldoOk = true;
        })
        .catch((err) => {
          this.vendas_semana = [];
          this.vendas_device = [];
          this.loading_statements = false;
          console.error(err);
        })
        .finally(() => this.$refs.areaChart.mountedChart(this.vendas_semana));
    },
    fetchBanner() {
      if(!this.$store.getters.getVerifyPermissions.Dashboard) return false;
      let data = {
        types: ["banner_url_disk"],
      };

      serviceDashboard
        .search(data)
        .then((response) => {
          this.banner_url = response.upload_path;
          this.url_redirect = response.url_redirect;
          this.openBannerInModal = response.in_modal;
        })
        .catch((err) => console.error(err));
    },
    fetchTopProducts() {
      if(!this.$store.getters.getVerifyPermissions.Dashboard) return false;
      this.top_products_load = true;
      this.top_products = [];

      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      let lastMonth = new Date();
      lastMonth.setDate(today.getDate() - 30);
      lastMonth.setHours(0, 0, 0, 0);

      let data = {
        types: ["top_products"],
        today: moment
          .tz(today, "America/Sao_Paulo")
          .utc()
          .format("YYYY-MM-DDT00:00:00-03:00"),
        last_week: moment
          .tz(lastWeek, "America/Sao_Paulo")
          .utc()
          .format("YYYY-MM-DDT00:00:00-03:00"),
        last_month: moment
          .tz(lastMonth, "America/Sao_Paulo")
          .utc()
          .format("YYYY-MM-DDT00:00:00-03:00"),
        currency_id: this.interAccess ? this.currency.id : 1,
      };

      serviceDashboard
        .search(data)
        .then((response) => {
          // Top products
          this.top_products = response.top_products;
          this.top_products_load = false;
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.loading = false;
          this.top_products_load = false;
        });
    },
    openPanel(sale_id) {
      this.$emit("openPanel", sale_id);
    },
    debounce: _.debounce(function () {
      this.sendLocalStorage();
    }, 5000),
    // funcção para enviar os dados do localStorage para o back
    sendLocalStorage() {
      if (this.userID) {
        let data = {
          id: this.userID,
          local_storage: JSON.stringify(window.localStorage),
        };
        serviceUser.update(data);
      }
    },
    initAll() {
      if (typeof localStorage.user !== 'undefined') {
        if (JSON.parse(localStorage.user).country) {
          if (JSON.parse(localStorage.user).country.base_currency) {
            this.currency = JSON.parse(localStorage.user).country.base_currency;
          }
        }
      }

      this.getStatusValidation();
      // metas
      this.fetchMetas();
      
      this.fetchTopProducts();
      this.fetchBanner();
      this.fetchVendasRecentes();
      this.fetchHeader();

      this.debounce();
      window.$crisp = [];

      this.existExternalParams();

      this.checkInvite();
      // add crisp no greenn
      // let notify = document.getElementById("1WZET722PJibpmL_crisp");
      // if (notify === null) {
      //   this.getEvaluation(true).then((resp) => {
      //     if (resp.is_evaluation) {
      //       window.$crisp.push(["do", "session:reset"]);
      //     }
      //     this.crispWitUser();
      //   });

      //   this.appendScriptCrisp();
      // } else {
      //   // const crisp = document.querySelector(".crisp-client");
      //   // if (crisp) {
      //   //   crisp.style.display = "block";
      //   // }
      //   this.getEvaluation(true).then((resp) => {
      //     if (resp.is_evaluation) {
      //       window.$crisp.push(["do", "session:reset"]);
      //     }
      //     this.crispWitUser();
      //   });
      // }
      // fim crisp
      // add userReport no greenn

      // if (!document.querySelector("#userreport-launcher-script")) {
      //   setTimeout(() => {
      //     let reportScript = document.createElement("script");
      //     reportScript.setAttribute(
      //       "src",
      //       "https://sak.userreport.com/gdigital/launcher.js"
      //     );
      //     reportScript.setAttribute("id", "userreport-launcher-script");
      //     document.getElementsByTagName("head")[0].appendChild(reportScript);
      //   }, 1000);
      // }
    },
    /**
     * Refresh dashboard data with selected currency filter
     * @param coin - Object - object of selected currency
     * @return no return
     * @author Sandro Souza
     */
    updateCoin(coin) {
      this.currency = coin;
      this.fetchVendasRecentes();
      this.fetchHeader();
      this.fetchTopProducts();
    },
    openComissions() {
      this.$router.push({
        path: "/comissoes",
      });
    },
  },
  mounted() {
    this.initAll();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    let userInterIds = process.env.USER_INTER.substring(
      1,
      process.env.USER_INTER.length - 1
    ).split(",");
    this.interAccess = userInterIds.some(
      (userId) => parseInt(userId) === this.userID
    );
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
/* box valores */
.box-valores {
  display: flex;
  width: 600px;
  justify-content: space-between;
  align-items: center;
}

.item-valor {
  display: block;
}

.item-valor span {
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
}

.item-valor p {
  font-size: 22px;
  font-weight: 600;
  margin: 10px 0;
  text-align: right;
}

// Conteudo Dashboard
.container {
  display: flex;
  flex-direction: column;
}

.conteudo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* Conteudo centralizado */
.center {
  width: 640px;
}

.banner {
  background: linear-gradient(160.37deg,
      #002363 20.71%,
      rgba(0, 228, 160, 0) 206.96%);
  width: 100%;
  border-radius: 5px;
  display: flex;
  margin-bottom: 20px;
  text-decoration: none;
  background: #f7f7f7;
  overflow: hidden;
  object-fit: contain;
}

.banner-info h4 {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.banner-info p {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin: 10px 0 30px 0;
}

.banner-info a {
  padding: 12px 30px;
  background: transparent;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid #fff;
  text-decoration: none;
  transition: 0.3s ease;
}

.banner-info a:hover {
  color: #fff;
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
  .conteudo {
    display: block;
  }

  .center {
    width: 100%;
  }

  .banner-info h4 {
    font-size: 16px;
  }

  .banner-info p {
    font-size: 14px;
    margin: 10px 0 20px 0;
  }

  .banner-info a {
    padding: 10px 20px;
    font-size: 13px;
  }

  .banner-ilustra img {
    height: 150px;
  }
}

.grafico-area {
  /* width: 100%; */
  margin: 30px 0 40px 0;
  position: relative;
  height: 300px;
}

/* Conteudo lateral */
.article {
  width: 390px;
  background: gray;
}

.info-week {
  font-size: 12px;
  text-align: right;
  display: block;
  margin-top: 15px;
  color: #81858e;
}

.indicadores {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
}

.indicadores .item {
  background: #fff;
  // box-shadow: 2px 4px 10px rgba(237, 237, 240, 0.2);
  border: 1px solid #f7f7f7;
  border-radius: 5px;
  padding: 25px 35px;
  // padding-bottom: 15px;
  width: 48%;
  transition: 0.3s;
  cursor: default;
}

.indicadores .item:hover {
  transform: scale(1.05);
}

.indicadores .item:first-child {
  border-color: rgba(0, 33, 99, 0.1);
  box-shadow: none;
}

.indicadores .item p {
  display: flex;
  font-size: 14px;
  font-weight: normal;
  margin: 12px 0;
}

.indicadores .item p img {
  margin-left: 10px;
}

.indicadores .item h4 {
  font-size: 18px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .info-week {
    text-align: left;
  }

  .indicadores {
    display: block;
  }

  .indicadores .item {
    width: 100%;
  }

  .indicadores .item+.item {
    margin-top: 10px;
  }
}

.preload {
  background: rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  width: 80%;
  color: transparent;
  animation: preload 0.8s forwards linear infinite alternate;
}

.top {
  /* background: silver; */
  margin-bottom: 30px;
}

.top h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.top-lista {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top-lista+.top-lista {
  border-top: 0.5px solid rgba(0, 0, 0, 0.03);
}

.top-lista .dados-produto {
  display: flex;
  align-items: center;
}

.top-lista .dados-produto .icone {
  /* width: 60px;
  height: 60px; */
  padding: 20px;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
}

.icone-imagem {
  background: #f7f7f7;
  padding: 0;
  width: 80px;
  max-width: 80px;
  height: 80px;
  margin-right: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-lista h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}

.top-lista span {
  color: #81858e;
  font-size: 13px;
  display: initial;
}

.valores {
  text-align: right;
}

@media screen and (max-width: 768px) {
  .top h4 {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .top-lista .dados-produto .icone {
    padding: 13px;
  }

  .icone-imagem {
    width: 60px;
    max-width: 60px;
    height: 60px;
    margin-right: 30px;
  }

  .top-lista h5 {
    font-size: 13px;
    margin-bottom: 10px;
  }

  .top-lista span {
    font-size: 12px;
    white-space: nowrap;
  }
}

/* prealod */
/* .load {
  background: tomato;
} */

.load .dados-produto img,
.load .dados-transacao img {
  filter: invert(50%);
}

.load .dados-produto .dado h5,
.load .up h5,
.load .dados-produto .dado span,
.load .up span,
.load .down span {
  background: rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  width: 200px;
  color: transparent;
  display: block;
  animation: preload 0.8s forwards linear infinite alternate;
}

.load .up h5 {
  width: 120px;
  height: 15px;
  border-radius: 3px;
}

.load .up span,
.load .down span {
  width: 80px;
  height: 15px;
  border-radius: 3px;
}

.load .down span:nth-child(2) {
  width: 100px;
  height: 15px;
  border-radius: 3px;
}

.load .dados-produto .dado span {
  width: 300px;
}

.load .valores h5,
.load .valores span {
  background: rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  width: 100px;
  color: transparent;
  display: block;
  animation: preload 0.8s forwards linear infinite alternate;
}

.card-transacoes {
  width: 400px;
  background: #ffffff;
  // box-shadow: 2px 4px 10px rgba(237, 237, 240, 0.2);
  border: 1px solid #f7f7f7;
  border-radius: 5px;
  padding: 50px;
}

.saldos span {
  font-size: 14px;
  font-weight: 600;
}

.saldo-livre {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 13px auto;
}

.saldo-livre h5 {
  color: #002363;
  font-size: 32px;
  font-weight: 600;
}

#no-saldo {
  background: rgba(0, 0, 0, 0.03);
  width: 220px;
  border-radius: 5px;
  transition: 0.3s;
  color: transparent;
}

.block-prelod {
  width: 100px !important;
}

.saldo-livre .btn-esconde {
  padding: 13px;
  background: rgba(0, 0, 0, 0.03) url("../../assets/img/icons/eye-off.svg") no-repeat center center;
  border: 1px solid rgba(0, 0, 0, 0.03);
  border-radius: 50%;
  cursor: pointer;
  height: 45px;
  width: 45px;
}

.btn-esconde.ativo {
  border: 1px dashed #333;
  background: rgba(0, 0, 0, 0.03) url("../../assets/img/icons/eye.svg") no-repeat center center;
}

.saldo-block span {
  font-size: 13px;
  font-weight: 600;
}

.saldo-block h5 {
  font-size: 13px;
  color: #dbaa00;
  font-weight: 600;
  margin-top: 5px;
}

@media screen and (max-width: 768px) {

  .card-transacoes,
  .card-validation {
    width: 100% !important;
    padding: 40px 20px;
    // max-width: 375px;
    max-width: 100%;
  }

  .saldo-livre {
    margin: 0 auto;
  }

  .saldo-livre h5 {
    font-size: 24px;
  }

  .ver-vendas {
    width: 100%;
  }
}

/* grafico fake */
.grafico-bar {
  margin: 25px auto;
  position: relative;
  height: 220px;
}

/* Transacoes recentes */

.transacoes-recentes .titulo {
  display: flex;
  justify-content: space-between;
}

.transacoes-recentes .titulo span,
.titulo-grafico span {
  font-weight: 600;
  font-size: 14px;
}

.titulo-grafico {
  margin-top: 30px;
}

.transacao {
  margin: 20px 0;
}

.transacao span {
  font-size: 12px;
  font-weight: normal;
}

.transacao .item {
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.3s;
}

.transacao .item:hover {
  transform: scale(1.02);
}

.transacao .item+.item {
  margin-top: 5px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.03);
}

.dados-transacao {
  display: flex;
  align-items: center;
}

.dados-transacao .icone {
  width: 60px;
  height: 60px;
  /* padding: 18px 12px; */
  // padding: 20px;
  background: #f7f7f7;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.up,
.down {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 235px;
}

.up {
  margin-bottom: 5px;
}

.up h5 {
  font-size: 13px;
  font-weight: 600;
}

.up span {
  font-size: 13px;
  color: #81858e;
  font-weight: normal;
}

.down span {
  font-size: 11px;
  color: #81858e;
  font-weight: normal;
}

@media screen and (max-width: 768px) {

  .up,
  .down,
  .dados-transacao {
    width: 100%;
  }

  .dado {
    flex: 1;
  }

  .grafico-area {
    // max-width: 360px;
    max-width: 100%;
  }
}

.ver-vendas {
  width: 100%;
  margin-top: 20px;
  color: #002e74;
  background: #ffffff;
  border: 1px solid #5592d5;
  border-radius: 30px;

  &:hover {
    text-decoration: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;
}

.erro-img {
  max-width: 50px;
  filter: invert(50%);
}

.icone.erro-img {
  max-width: 35px;
}

// loading
.container-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  animation: preload 5s forwards linear infinite alternate;
}

.top-icons,
.top-icons .apps {
  display: flex;
  align-items: center;
  gap: 30px;
}

.top-icons .apps {
  gap: 15px;
}

.top-icons .apps img {
  cursor: pointer;
}

// central de ajuda
.central-ajuda {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  color: #000;
  font-weight: 600;
  transition: 0.3s;
}

.central-ajuda img {
  max-width: 18px;
  filter: invert(0) brightness(0);
  transition: 0.3s;
}

.central-ajuda:hover {
  color: #2a63ab;
  text-decoration: none;
}

.central-ajuda:hover img {
  filter: invert(0) brightness(1);
}

@media screen and (max-width: 768px) {
  .central-ajuda {
    max-width: 160px;
    margin-top: 20px;
  }
}

.float-app {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999999 !important;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);

  width: 100%;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.float-app .close-icon {
  padding: 10px;
  cursor: pointer;
}

.float-app .close-icon img {
  filter: invert(0) brightness(10);
}

.float-app p {
  color: #fff;
  font-size: 13px;
  font-weight: 600;
}

.float-app p:last-child {
  font-size: 12px;
  font-weight: normal;
}

.float-app .acoes {
  display: flex;
}

.float-app .acoes button {
  height: 40px;
  padding: 0 13px !important;
  font-size: 12px;
  margin-left: 20px !important;
}

.periodo-grafico {
  display: block;
  font-size: 12px !important;
  color: #81858e;
  font-weight: normal !important;
}

.card-validation {
  width: 400px;
  background: rgba(33, 51, 210, 0.1);
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 30px;
  display: flex;
}

.card-validation p {
  color: #2a63ab;
  font-size: 13px;
  line-height: 1.5;
}

.card-validation button {
  height: 40px !important;
  font-size: 13px !important;
  padding: 10px 15px !important;
  margin-top: 15px;
  text-align: right;
}

.banner-default-loading {
  justify-content: center;
  align-items: center;
  height: 130px;
}
</style>
