<template>
  <div class="modal-banner" v-if="isVisible">
    <!-- Modal do Banner -->
    <b-modal id="modal-banner" v-model="showModal" :size="isMobile ? 'sm' : 'lg'" hide-footer hide-header
      :title="`${$t('modals.modal_banner')}`">
      <div class="fast-modal">
        <button class="close-button" @click="showModalBanner = showModal = false">×</button>
        <div v-if="url" class="fast-modal-container">
          <iframe 
            id="fastframeBanner" 
            width="800" 
            height="800" 
            style="height: 100%; width: 100%; transform: scale(1); border: none;"
            :src="url"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import utils from '@/mixins/utils';

export default {
  name: 'ModalBanner',
  mixins: [utils],
  props: {
    url: {
      type: String,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isVisible: false,
      showModal: false,
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  methods: {
    changeModalBanner() {
      this.showModal = !this.showModal;
    }
  },
  mounted() {
    this.isVisible = this.isAuthenticated;
  },
  watch: {
    isAuthenticated(newVal) {
      this.isVisible = newVal;
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-banner {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;

}

.close-button {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  background: white;
  border: none;
  color: #333;
  font-size: 24px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9999;
  transition: all 0.2s ease;
  border-radius: 4px;

  &:hover {
    transform: rotate(90deg);
    color: #000;
  }

  &:focus {
    outline: none;
  }
}

.fast-modal {
  width: 100%;
}

.fast-modal-container {
  padding: 0px;
  height: 800px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    height: 500px;
  }
}

.fast-modal-iframe {
  display: flex;
  justify-content: center;
}

:deep(.modal-dialog) {
  @media screen and (max-width: 768px) {
    margin-top: 80px;
  }
}
</style>